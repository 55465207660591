import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {type[]} selectedValues - a list of VPCs that should be selected if available
 * @param {boolean} deselect - defines if the deselect VPCs button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the VPC dropdown
 * @param {boolean} listAll - display all resources that the user has permission to see/edit
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function VPCDropdown({
  label,
  id,
  onchange,
  onrefresh,
  selectedValues,
  deselect,
  multiple,
  listAll,
  disableUrlParams,
}) {
  const labelText = label ? label : 'VPC Id';
  const selectId = id ? id : 'vpc-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {
    list_all: listAll ? listAll : false,
  };
  const multiSelect = multiple ? true : false;
  const urlParameters = !disableUrlParams ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more VPCs -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} VPCs selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select a VPC -
      </option>
    </select>
  );

  // Refresh button to reload the VPC data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-vpcs"
      title="Reload VPCs">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-vpcs"
      title="De-select all vpcs">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const vpcDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="vpc-id" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedVPCId = selectedOption.val();
    const selectedId = selectedOption.data('id');
    const selectedUId = selectedOption.data('uuid');

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('vpc_id');
      if (selectedVPCId && selectedVPCId !== previousUrlParam) {
        setSearchParamsUrl({ vpc_id: selectedVPCId });
      }
    }

    if (onchange && selectedVPCId) {
      onchange('vpc_id', 'network', selectedOption, selectedVPCId, selectedId, selectedUId);
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const dropdownSettings = Object.assign(
      {
        selectedValues,
        multiSelect,
        urlParameters,
      },
      event.detail,
    );

    let additionalParams = {
      list_all: event?.detail?.allResources,
    };

    loadVPCs(
      select,
      refreshIcon,
      dropdownSettings.selectedValues,
      dropdownSettings.multiSelect,
      dropdownSettings.urlParameters,
      headers,
      additionalParams,
    );
  });

  // Reload the VPC records from the REST API (with cache-control headers)
  $(refreshButton).on('click', event => {
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadVPCs(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params);
    }
  });

  // De-select all VPCs from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the VPC records from the REST API (without cache-control headers)
  loadVPCs(select, refreshIcon, selectedValues, multiSelect, urlParameters, {}, params);
  return vpcDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValues
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {object} headers
 * @param {object} params
 */
async function loadVPCs(select, refreshIcon, selectedValues, multiSelect, urlParameters, headers, params) {
  $(refreshIcon).addClass('fa-spin');
  select.querySelectorAll('option:not(.defaultDisabled)').forEach(el => el.remove());
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    // Helper function to process items and update the dropdown
    const processItems = (items, selected_vpc_id, number_vpcs, options) => {
      options = options.concat(
        items.map(vpc => {
          let opt = document.createElement('option');
          opt.value = vpc.vpc_id;
          opt.dataset.cidr = vpc.cidr;
          opt.dataset.id = vpc.id;
          opt.dataset.uuid = vpc.uuid;
          opt.dataset.content = `<span class="bs-dropdown-badge">${
            vpc.vpc_id
          }</span><span class="bs-dropdown-badge cidr">${vpc.cidr}</span> ${
            vpc.ipv6_cidr ? `<span class="bs-dropdown-badge cidr">${vpc.ipv6_cidr}</span>` : ''
          }<span class="bs-dropdown-item-text">${vpc.name ? vpc.name : '-'}</span><span class="bs-dropdown-badge new">${
            vpc.aws_account_id
          }</span>`;
          if (Object.prototype.hasOwnProperty.call(number_vpcs, vpc.network_type)) {
            number_vpcs[vpc.network_type] += 1;
          } else {
            number_vpcs.other += 1;
          }
          opt.selected =
            (selected_vpc_id && selected_vpc_id == vpc.vpc_id) ||
            (selectedValues && selectedValues.includes(vpc.vpc_id))
              ? true
              : false;
          return opt;
        }),
      );

      // Sort the selectpicker options in asc order by the option text
      options.sort((a, b) => a.value.localeCompare(b.value));
      // Add all options to the selectpicker
      options.forEach(option => {
        select.appendChild(option);
      });

      return options;
    };

    // Helper function to dispatch the custom event
    const dispatchDropdownEvent = (options, number_vpcs, params, page) => {
      const event = new CustomEvent('dropdown-options-loaded', {
        detail: {
          number_active: options?.length,
          number_private: number_vpcs.private,
          number_public: number_vpcs.public,
          number_other: number_vpcs.other,
          list_all: params.list_all,
          page,
        },
      });
      select.dispatchEvent(event);
    };

    // Initial call to getVPCs
    let { items, next_token } = await baseApiAxios.getVPCs(null, { list_all: params.list_all, short: true });

    let vpcs_length = -1;
    let page = 0;
    let options = [];
    const selected_vpc_id = !multiSelect && urlParameters && getSearchParamsUrl('vpc_id');
    let number_vpcs = { private: 0, public: 0, other: 0 };

    // Process the first batch of items
    options = processItems(items, selected_vpc_id, number_vpcs, options);
    dispatchDropdownEvent(options, number_vpcs, params, page);
    $(select).trigger('changed.bs.select');
    page += 1;

    // Continue fetching paginated results using the token
    while (next_token) {
      ({ items, next_token } = await baseApiAxios.getVPCs(null, {
        next_token,
        list_all: params.list_all,
        short: true,
      }));
      vpcs_length = items.length;

      // Process the next batch of items
      options = processItems(items, selected_vpc_id, number_vpcs, options);
      dispatchDropdownEvent(options, number_vpcs, params, page);

      if (vpcs_length !== 1000) $(select).trigger('changed.bs.select');
      page += 1;
    }
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}
